<div class="modal-header bg-black lter">

    <div class="row">
        <div class="col-xs-10 col-sm-10 col-md-11">
            <h5 class="modal-title text-white font-thin font-bold ">
                <span>{{faculty.full_name}} - {{faculty.meeting_role}}</span>
            </h5>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-1 ">
            <button class="pull-right text-white modal-btn-hdr" ng-click="done()">
                <i class="glyphicon glyphicon-remove-circle"></i>
            </button>
        </div>
    </div>

</div>
<div class="modal-body no-padder bg-white-only">

    <div class="hbox hbox-auto-xs bg-light">
        <div class="col bg-white-only">
            <div class="vbox">
                <div class="row-row">
                    <div class="cell scrollable">
                        <div class="cell-inner">
                            <div class="wrapper m-l-xxl m-r-xxl">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div ng-show="faculty.review_status == 'Mitigated'" class="font-bold">
                                            <p class="text-success"><span class="fa fa-fw fa-check-circle text-success"></span> All conflicts have been mitigated.</p>
                                        </div>

                                        <div>
                                            <div id="resolution_methods" class="form-group">

                                                <h3>Conflict Mitigation Strategy</h3>
                                                <em>Using the list below, identify which mitigation strategy(ies) will
                                                    be used. You may select multiple strategies. You can also identify
                                                    your own strategies for mitigation.</em>
                                                <div ng-repeat="option in conflict_resolution_methods track by $index"
                                                     class="checkbox m-l-sm">
                                                    <div ng-if="!faculty.planning_role">
                                                        <label ng-show="option.slug != 'recuse'">
                                                            <input type="checkbox"
                                                                   id="{{option.name}}"
                                                                   ng-model="resolution_methods[option.name]"
                                                                   value={{option.id}}>
                                                            <span ng-bind-html="option.description | unsafe"></span>
                                                        </label>
                                                    </div>
                                                    <div ng-if="faculty.planning_role">
                                                        <label ng-show="option.slug != 'attest'">
                                                            <input type="checkbox"
                                                                   id="{{option.name}}"
                                                                   ng-model="resolution_methods[option.name]"
                                                                   value={{option.id}}
                                                                   >
                                                            <span ng-bind-html="option.description | unsafe"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="m-b-sm">
                                                <em>Please add any comments (e.g. steps taken to mitigate, notes from correspondence with faculty, etc)</em>
                                                <textarea ng-model="faculty.resolution_method_note" style="width: 100%; height: 25px; width: 100%"></textarea>
                                            </div>
                                            <div ng-show="faculty.notes.length">
                                                <p><strong>Comments</strong></p>
                                                <ul class="list-group">
                                                    <li class="list-group-item" ng-repeat="note in faculty.notes">
                                                        {{note.note}}
                                                        | <em>{{note.author.full_name}} - {{formatMomentDate(note.updated_at, 'MMM D, YYYY')}}</em></em></li>
                                                </ul>
                                            </div>
                                            <div class="line line-single line-lg b-b b-t b-primary2"></div>
                                            <p>
                                                <span class="font-bold">Before certifying that all conflicts have been mitigated, you must do at least one of the following:</span>
                                            </p>
                                            <p>
                                                (1) check the box indicating that no further action is required,<br>
                                                (2) select at least one assignment above as requiring slide
                                                review,<br>
                                                or<br>
                                                (3) write in specific comments/recommendations
                                            </p>
                                            <p>
                                                <span class="font-bold">To complete the review, you must certify below that all conflicts have been mitigated</span>
                                            </p>
                                            <div class="checkbox">
                                                <label class="i-checks i-checks-sm">
                                                    <input type="checkbox"
                                                           name="conflicts_resolved"
                                                           ng-model="conflicts_resolved"

                                                    > <i></i>
                                                    I certify that all conflicts have been mitigated
                                                </label>
                                            </div>

                                            <div class="text-center m-t-sm">
                                                <button type="button" class="btn-success btn"
                                                        ng-click="finishReview()">
                                                    <span ng-show="faculty.review_status == 'Mitigated'">Update</span>
                                                    <span ng-show="faculty.review_status != 'Mitigated'">Save</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <uib-accordion>
                                            <div uib-accordion-group class="panel-default" ng-init="open = true"
                                                 is-open="open">
                                                <uib-accordion-heading>
                                                    Disclosures
                                                    <i class="pull-right glyphicon"
                                                       ng-class="open ? 'glyphicon-chevron-down':'glyphicon-chevron-right'"></i>
                                                </uib-accordion-heading>

                                                <div class="bg-light lter b">
                                                    <div class="wrapper-sm">
                                                        <div ng-include="'partials/disclosure_details.html'"></div>
                                                    </div>
                                                </div>

                                            </div>
                                        </uib-accordion>
                                        <uib-accordion>
                                            <div uib-accordion-group class="panel-default" ng-init="open = true"
                                                 is-open="open">
                                                <uib-accordion-heading>
                                                    Assignments
                                                    <i class="pull-right glyphicon"
                                                       ng-class="open ? 'glyphicon-chevron-down':'glyphicon-chevron-right'"></i>
                                                </uib-accordion-heading>

                                                <div class="table-responsive">
                                                    <table ng-show="faculty.assignments.length"
                                                           class="table table-responsive table-condensed table-hover bg-white b-a m-b-none">
                                                        <tr>
                                                            <th>Day/Time</th>
                                                            <th colspan="2">Session</th>
                                                            <th>Title</th>
                                                            <th>Role</th>
                                                            <th>Status</th>
                                                        </tr>
                                                        <tr ng-repeat="assignment in faculty.assignments">
                                                            <td>
                                                                {{formatMomentDate(assignment.datetime_start, 'MM/DD/YYYY h:mm A')}}
                                                            </td>
                                                            <td>{{assignment.session_type}}</td>
                                                            <td>{{assignment.breadcrumb}}</td>
                                                            <td>{{assignment.title}}</td>
                                                            <td>{{assignment.moderator_role}}</td>
                                                            <td>
                                                                <crf-status-assignment status-type="{{assignment.status}}"></crf-status-assignment>
                                                            </td>
                                                        </tr>

                                                    </table>
                                                    <p ng-show="!faculty.assignments.length">No active assignments</p>
                                                </div>
                                            </div>
                                        </uib-accordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
